.chat-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  
  flex-direction: row;
  align-items: center;
  padding: 20px;
  max-width: 400px;
  margin: 20px auto;
  transition: transform 0.2s ease;
}

.chat-card:hover {
  transform: scale(1.05);
}

.avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.chat-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h2 {
  margin: 0;
  font-size: 24px;
}

.chat-btn {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease;
}

.chat-btn:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .chat-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .avatar img {
    margin-bottom: 15px;
  }
}

